/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import e from "../core/Collection.js";
import { referenceSetter as r } from "../core/collectionUtils.js";
import s from "../core/Logger.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
function a(t, e, r) {
  if (t) for (let s = 0, o = t.length; s < o; s++) {
    const o = t.at(s);
    if (o[e] === r) return o;
    if ("group" === o?.type) {
      const t = a(o.tables, e, r);
      if (t) return t;
    }
  }
}
const n = n => {
  let l = class extends n {
    constructor(...t) {
      super(...t), this.tables = new e(), this.addHandles([this.tables.on("after-add", t => {
        const e = t.item;
        e.parent && e.parent !== this && "tables" in e.parent && e.parent.tables.remove(e), e.parent = this, "feature" !== e.type && s.getLogger(this).error(`Layer 'title:${e.title}, id:${e.id}' of type '${e.type}' is not supported as a table and will therefore be ignored.`);
      }), this.tables.on("after-remove", t => {
        t.item.parent = null;
      })]);
    }
    destroy() {
      const t = this.tables.toArray();
      for (const e of t) e.destroy();
      this.tables.destroy();
    }
    set tables(t) {
      this._set("tables", r(t, this._get("tables")));
    }
    findTableById(t) {
      return a(this.tables, "id", t);
    }
    findTableByUid(t) {
      return a(this.tables, "uid", t);
    }
  };
  return t([o()], l.prototype, "tables", null), l = t([i("esri.support.TablesMixin")], l), l;
};
export { n as TablesMixin };